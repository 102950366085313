import { CONSTANTS } from "../helpers/variables";
import axiosService from "./axios.service";

class UserService {
    async getUsers(keywords, currentPage, perPage, sortBy, sortDesc) {
        let keyword = keywords ? keywords : ""; 
        let users = await axiosService.get(CONSTANTS.APIURL + `users-list?keywords=${keyword}&page=${currentPage}&per_page=${perPage}&column=${sortBy}&orderby=${sortDesc}`);
        return users;
    }
    async getUserById(id) {
        let users = await axiosService.get(CONSTANTS.APIURL + `user?id=${id}` );
        return users;
    }
    async addUser(...user) {
        return await axiosService.post(CONSTANTS.APIURL + 'register', ...user);
    }
    async updateUser(...user) {
        return await axiosService.put(CONSTANTS.APIURL +  'users', ...user);
    }
    async deleteUser(id) {
        return await axiosService.detete(CONSTANTS.APIURL +`users?id=${id}`);
    }
}

export default new UserService();