import { CONSTANTS } from "../helpers/variables";
import axiosService from "./axios.service";

class ExpertService {
    async getExperts(keywords, currentPage, perPage, sortBy, sortDesc) {
        let keyword = keywords ? keywords : ""; 
        let experts = await axiosService.get(CONSTANTS.APIURL + `experts-list?keywords=${keyword}&page=${currentPage}&per_page=${perPage}&column=${sortBy}&orderby=${sortDesc}`);
        return experts;
    }
    async getExpertById(id) {
        let Experts = await axiosService.get(CONSTANTS.APIURL + `expert?id=${id}` );
        return Experts;
    }
    async addExpert(...expert) {
        return await axiosService.post(CONSTANTS.APIURL + 'experts', ...expert);
    }
    async updateExpert(...expert) {
        return await axiosService.put(CONSTANTS.APIURL +  'experts', ...expert);
    }
    async deleteExpert(id) {
        return await axiosService.detete(CONSTANTS.APIURL +`experts?id=${id}`);
    }
    async getExpert() {
        return await axiosService.get(CONSTANTS.APIURL +`experts`);
    }
}
export default new ExpertService();